<template>
  <div id="Incentives" class="container-fluid container-lg pb-4">
    <div class="row" id="IncentivesHeader">
      <div class="col-12 text-center">
        <h1 class="d-inline pr-4">Find incentives in your area</h1>

        <label
          >Your state:&nbsp;&nbsp;<StatePicker
            v-model="statePicker"
            @change="changeState(statePicker)"
        /></label>
      </div>
    </div>
    <div class="row incentives-background">
      <div class="col-12 col-md-10 offset-md-1 px-0 px-md-2">
        <div id="IncentivesPanel" class="my-5" style="color: black">
          <IncentivesPanel :incentives="incentives" :state="selectedState" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const StatePicker = () => import("Components/StatePicker");
const IncentivesPanel = () => import("Components/Incentives/IncentivesPanel");
import LocationService from "Services/LocationService";
import { Constants } from "Services/Constants";

export default {
  metaInfo() {
    var stateText = this.state ? `in ${this.state}` : "by State"
    return {
      title: `Find EV Incentives and Rebates ${stateText}`,
      meta: [
        {
          name: "description",
          content:
            `Find EV incentives and rebates ${this.stateText}. Find federal and state offers, including EV rebates, financing, home charger incentives, and tax credits.`,
        },
      ],
    };
  },
  props: ["state"],
  data() {
    const state = this.getStateFromAbbr(this.state || this.getDefaultState());
    return { incentives: null, statePicker: state, selectedState: state };
  },
  async mounted() {
    if (this.state) {
      await this.getIncentives();
    } else {
      await this.changeState(this.selectedState);
    }
  },
  methods: {
    getDefaultState() {
      return this.$store.state.addressState || "CA";
    },
    async changeState(state) {
      this.selectedState = state;

      this.$router.push({
        name: "incentives",
        params: { state: state.abbr },
      });
      await this.getIncentives();
    },
    async getIncentives() {
      this.$store.commit("loading");
      this.incentives = await LocationService.getIncentivesByState(
        this.selectedState.abbr
      );
      this.$store.commit("loaded");
    },
    getStateFromAbbr(stateAbbr) {
      if (!stateAbbr) return null;
      return Constants.states.find((x) => x.abbr == stateAbbr);
    },
  },
  components: {
    IncentivesPanel,
    StatePicker,
  },
};
</script>

<style lang="scss" scoped>
#IncentivesHeader {
  padding-top: 30px;
  padding-bottom: 20px;
  h1 {
    color: white;
    font-family: $primary-header-font-family;
    font-size: 2.0625rem;
  }

  label {
    color: #bdbdbd;
    font-weight: 700;
    margin-right: 11px;
    top: -5px;
    position: relative;
  }
  ::v-deep select {
    padding: 5px;
  }
}

.incentives-background {
  background: url("~Images/map_bg.webp");
  border-top: 11px solid #797979;
  border-bottom: 11px solid #797979;
}
</style>
